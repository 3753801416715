.businessSolutions {
  display: none;
  @media (--screen-lg) {
    display: inline-flex;
  }
}

.listAProperty {
  display: none;
  @media (--screen-md) {
    display: inline-flex;
  }
}

.savedProperties,
.savedSearches {
  display: flex;
  align-items: center;
  color: var(--color-gray-500);

  .navAnchor {
    height: 50px;
    font-weight: var(--font-weight-medium);
    font-size: 13px;
  }

  .navAnchor:hover {
    color: var(--color-black);
  }

  .navAnchor:active {
    color: var(--color-gray-400);
  }
}
