.pageHeaderFixed {
  position: sticky;
  top: 0;
  z-index: var(--z-index-2x);
}

.pageHeaderRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.pageHeader {
  --logo-color: var(--page-header-logo-color);
  width: 100%;
  height: var(--page-header-height);
  background: var(--page-header-background);
  color: var(--page-header-text);
  border-bottom: var(--page-header-border-bottom);
  display: flex;
  align-items: center;
  position: var(--page-header-position);
  z-index: var(--z-index-5x);
  flex-direction: row;
  justify-content: space-between;
  --drawer-menu-trigger-padding: 0;
  --drawer-menu-trigger-background-color: var(--color-gray-100);
  --drawer-menu-trigger-back-width: var(--space-10x);
  --page-header-back-width: var(--space-10x);
  --page-header-back-height: var(--space-8x);
  --page-header-back-border-radius: var(--space-11x);
  --page-header-buttons-gap: var(--space-2x);
  --page-header-back-font-size: var(--font-size-md);
  --page-header-buttons-padding: 0 var(--space-3-5x) 0 var(--space-3x);

  @media (--screen-lg) {
    --page-header-buttons-padding: 0 var(--space-3-5x) 0 var(--space-4x);
  }
}

.pageHeaderLogoLink {
  display: inline-flex;
  width: 137px;

  @media (--screen-3xl) {
    width: 177px;
  }
}

.pageHeaderBack {
  color: var(--page-header-back-color);
  background: var(--page-header-back-background-color);
  height: var(--page-header-back-height, 50px);
  font-size: var(--page-header-back-font-size, var(--font-size-xl));
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--page-header-back-width, 52px);
  padding: 0;
  border-radius: var(--page-header-back-border-radius, 0);
}

.pageHeaderBack:hover {
  background: var(--page-header-back-background-color);
}

.pageHeaderBack:active {
  background: var(--page-header-back-background-color-active);
}

.pageHeaderLogo {
  position: relative;
  flex: 1;
  width: auto;
  height: var(--page-header-logo-height, 38px);
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.pageHeaderLinks {
  display: none;
  list-style: none;
  font-size: 13px;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: 50px;

  @media (--screen-lg) {
    display: inline-flex;
  }
}

.pageHeaderLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 205px;
  height: 50px;
}

.pageHeaderButtons {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  gap: var(--page-header-buttons-gap, 0);
  padding: var(--page-header-buttons-padding, 0);
}

.pageHeaderMobileContent {
  flex-grow: 1;
  justify-content: flex-end;
  padding: 0 var(--space-2x) 0 var(--space-3x);
  display: flex;
  font-size: 14px;

  @media (--screen-md) {
    display: none;
  }
}

.placeholder {
  display: flex;
  justify-content: flex-end;
  width: auto;

  @media (--screen-md) {
    width: 205px;
  }

  @media (--screen-3xl) {
    width: 239px;
  }
}

.pageHeaderLoginLink {
  font-size: 13px;
  font-weight: var(--font-weight-medium);
  justify-content: flex-end;
  padding-right: var(--space-3x);
  height: 50px;
  color: var(--page-header-links-color);

  @media (--screen-lg) {
    padding-right: var(--space-6x);
  }
}

.pageHeaderLink {
  color: var(--page-header-links-color);
  font-weight: var(--page-header-links-font-weight);
  min-width: var(--space-12x);
  text-decoration: var(--page-header-links-text-decoration);
  padding: 0 var(--space-3x);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  height: 100%;

  &button {
    padding: 0;
  }
}

.pageHeaderLink:hover,
.pageHeaderLoginLink:hover {
  color: var(--color-black);
  text-decoration: underline;
}

.pageHeaderLink:active,
.pageHeaderLoginLink:active {
  color: var(--color-gray-400);
}

.pageHeaderAuthLinksWrapper {
  list-style: none;
  height: 50px;
  align-items: center;
  color: var(--page-header-links-color);
  font-weight: var(--page-header-links-font-weight);
}
